<template>
  <v-container class="mt-0 pt-0" fluid>
    <v-card>
      <v-toolbar color="grey" height="40">
        <v-spacer></v-spacer>
        <input v-show="estado" title="solo aplica para cotizaciones" type="checkbox" id="ivachec" v-model="ivachec">
        <label v-show="estado" title="solo aplica para cotizaciones" for="ivachec">{{ 'IVA' }}</label>
        <v-btn icon dark @click="addItem()" v-if="isAdd || isUpdate" title="add carrito">
          <v-icon>mdi-cart-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headersShow"
          v-if="isShow"
          :items="cart"
          :items-per-page="99"
          class="elevation-1"
          hide-default-footer
          no-data-text='Añade un producto al carrito'
          :header-props="{
            sortByText: 'Ordenar',
            class: 'title',
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-text': 'Productos por Página',
          }">
          <template v-slot:[`item.imagenurl`]="{ item }">
              <v-row justify="center">
                <v-img
                  :src="item.imagenurl"
                  height="auto"
                  max-width="80px"
                >
                </v-img>
              </v-row>
          </template>
          <template v-slot:[`item.subtotal`]="{ item }">
            <v-flex>
              {{ parseFloat(item.quantity * item.listprice).toFixed(2) }}
            </v-flex>
          </template>
        </v-data-table>
        <v-data-table
          :headers="hearders"
          v-else-if="isAdd ||isUpdate"
          readonly
          :items="cart"
          :items-per-page="99"
          class="elevation-1"
          hide-default-footer
          no-data-text='Añade un producto al carrito'
          :header-props="{
            sortByText: 'Ordenar',
            class: 'title',
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-text': 'Productos por Página',
          }">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon class="px-3" title="Remover Producto" @click="removeItem(item)">
              mdi-delete
            </v-icon>
          </template>
           <template v-slot:[`item.productname`]="{ item }">
            <v-text-field v-if="item.tipo === 'GENE'"
              color="grey darken-4"
              v-model="item.productname"
              reverse>
            </v-text-field>
            <v-text-field  v-else
              v-model="item.productname"
              reverse
              readonly
              >
            </v-text-field>
          </template>
          <template v-slot:[`item.weight`]="{ item }">
            <v-text-field v-if="item.tipo === 'GENE'"
              color="grey darken-4"
              v-model="item.weight"
              reverse>
            </v-text-field>
            <v-text-field  v-else
              v-model="item.weight"
              reverse
              readonly
              >
            </v-text-field>
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            <v-text-field
              color="grey darken-4"
              v-model="item.quantity"
              @change="calculate()"
              @click="calculate()"
              min="1"
              max="1000"
              type="number"
              :rules="numberRule"
              reverse>
            </v-text-field>
          </template>
          <template v-slot:[`item.listprice`]="{ item }">
            <v-text-field v-if="item.tipo === 'DIEN' || item.tipo === 'GENE'"
              color="grey darken-4"
              v-model="item.listprice"
              @change="calculate()"
              @click="calculate()"
              min="1"
              :rules="numberRule"
              reverse>
            </v-text-field>
            <v-text-field v-else
              color="grey darken-4"
              v-model="item.listprice"
              @change="calculate()"
              @click="calculate()"
              min="1"
              type="number"
              :rules="numberRule"
              reverse
              readonly
              >
            </v-text-field>
          </template>
          <template v-slot:[`item.lista_precio`]="{ item }">
            <v-combobox
              :disabled="habilitar()"
              color="grey darken-4"
              v-model="item.lista_precio"
              @change="update(item)"
              :items="pricebookuser"
              item-value="text"
              max-width="50px"
              >
            </v-combobox>
          </template>
          <template v-slot:[`item.subtotal`]="{ item }">
            <v-flex>
              {{ parseFloat(item.quantity * item.listprice).toFixed(2) }}
            </v-flex>
          </template>
          <template v-slot:[`item.imagenurl`]="{ item }">
              <v-row justify="center">
                <v-img
                  :src="item.imagenurl"
                  height="auto"
                  max-width="80px"
                  title="Imagen Car"
                >
                </v-img>
              </v-row>
          </template>
        </v-data-table>
        <v-card>
          <v-card-text>
            <v-container>
              <v-layout row wrap>
                <v-flex
                  xs7
                  class="mt-2 font-weight-black"
                  d-flex justify-end>
                    SUB TOTAL:
                </v-flex>
                <v-flex
                  xs5
                  class="mt-2"
                  d-flex justify-end>
                    {{sub}}
                </v-flex>
                <v-flex
                  xs7
                  class="mt-2 font-weight-black"
                  d-flex justify-end>
                    PESO TOTAL Kg:
                </v-flex>
                <v-flex
                  xs5
                  class="mt-2"
                  d-flex justify-end>
                    {{cal}}
                </v-flex>
                <v-flex
                  xs7
                  class="mt-2 font-weight-black"
                  d-flex justify-end>
                    IVA:
                </v-flex>
                <v-flex
                  xs5
                  class="mt-2"
                  d-flex justify-end>
                    {{0}}
                </v-flex>
                <v-flex
                  xs7
                  class="mt-2 font-weight-black"
                  d-flex justify-end>
                    TOTAL:
                </v-flex>
                <v-flex
                  xs5
                  class="mt-2"
                  d-flex justify-end>
                    {{form.total}}
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <!-- Seccion para seleccionar los material del pedido ↓↓ -->
    <v-dialog v-model="dialog" max-width="75%" persistent>
      <v-card>
      <v-toolbar :color="profile.organization.color">
        <h1 class="title mt-2 white--text">
          CATALOGO DE PRODUCTOS
        </h1>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = !dialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
        <v-card-title>
         <v-col cols="12" sm="4" class="mt-0 pt-0">
         <v-autocomplete
          class="mt-7 pt-5"
          v-model="salesordertype"
          :items="pricebookuser"
          @click="buscarlista"
          @change="buscarlista"
          label="Condición de Venta"
          color="grey darken-4"
         ></v-autocomplete>
      </v-col>
          <v-spacer></v-spacer>
          <v-text-field
            class="mt-5 pt-5"
            v-model="searchDialog"
            append-icon="mdi-magnify"
            label="Buscar"
            color="grey darken-4"
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersDialog"
            :items="itemsDialog"
            :items-per-page="5"
            class="elevation-12 mt-0 pt-0"
            :search="searchDialog"
            no-data-text='Seleccione un producto al carrito'
            :header-props="{
              sortByText: 'Ordenar',
            }"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-text': 'Productos por Página',
            }">
            <template v-slot:[`item.select`]="{ item }">
              <v-icon
                title="Seleccionar"
                @click="selectItem(item)"
                x-large>
                mdi-check-circle-outline
              </v-icon>
            </template>
            <template v-slot:[`item.imagenurl`]="{ item }">
              <v-row justify="center">
                <v-img
                  :src="item.imagenurl"
                  height="auto"
                  max-width="100px"
                  title="Imagen"
                >
                </v-img>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapMutations, mapActions } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
import { salesorderItemCar } from '../../config/api/products';
import { salesorderForm, updateListaPrecio } from '../../config/api/salesorder';

export default {
  name: 'salesorder-list',
  data: () => ({
    dialog: false,
    pricebookuser: [],
    headers: [
      { text: 'Producto', value: 'imagenurl', align: 'center' },
      { text: 'Código', value: 'productcode', align: 'center' },
      { text: 'Descripción', value: 'productname', align: 'center' },
      { text: 'Peso Kg', value: 'weight', align: 'center' },
      {
        text: 'Cantidad', value: 'quantity', width: '7%', align: 'center',
      },
      {
        text: 'Lista de Precio', value: 'lista_precio', width: '10%', align: 'center',
      },
      { text: 'Centro', value: 'center', align: 'center' },
      { text: 'Almacen', value: 'almacen', align: 'center' },
      {
        text: 'Precio', value: 'listprice', width: '9%', align: 'center',
      },
      { text: 'SubTotal', value: 'subtotal', align: 'center' },
      // { text: 'Total', value: 'total', align: 'center' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    headersShow: [
      { text: 'Producto', value: 'imagenurl', align: 'center' },
      { text: 'Código', value: 'productcode', align: 'center' },
      { text: 'Descripción', value: 'productname', align: 'center' },
      { text: 'Peso Kg', value: 'weight', align: 'center' },
      {
        text: 'Cantidad', value: 'quantity', align: 'center',
      },
      { text: 'Lista de Precio', value: 'lista_precio', align: 'center' },
      { text: 'Centro', value: 'center', align: 'center' },
      { text: 'Almacen', value: 'almacen', align: 'center' },
      { text: 'Precio', value: 'listprice', align: 'center' },
      { text: 'SubTotal', value: 'subtotal', align: 'center' },
    ],
    search: '',
    headersDialog: [
      {
        text: 'Seleccionar', align: 'center', sortable: false, value: 'select',
      },
      { text: 'Producto', value: 'imagenurl', align: 'center' },
      { text: 'Descripción', value: 'productname', align: 'center' },
      // { text: 'Peso', value: 'weight', align: 'center' },
      { text: 'Centro', value: 'center', align: 'center' },
      { text: 'Almacen', value: 'almacen', align: 'center' },
      { text: 'Precio', value: 'listprice', align: 'center' },
      { text: 'Existencia', value: 'existencia', align: 'center' },
      { text: 'Limite Por Vendedor', value: 'limite', align: 'center' },
      { text: 'Código', value: 'productcode', align: 'center' },
    ],
    numberRule: [
      (v) => v.length > 0 || 'campo requerido',
      (v) => v > 0 || 'El valor debe ser mayor a cero',
    ],
    itemsDialog: [],
    id: null,
    searchDialog: '',
    request: {
    },
  }),
  computed: {
    ...mapState('userprofile', ['profile']),
    ...mapState('salesorder', ['form']),
    ...mapState('salesorder', ['itemCar', 'isShow', 'isAdd', 'isUpdate']),
    ...mapState('salesorder', ['ivachek', 'type', 'flete', 'items', 'cart', 'form', 'salesorderid']),
    ...mapState('SalesorderFrom', ['vv']),
    ...mapState('SalesorderForm', ['Dialog1']),
    estado: {
      get() {
        return this.form.sostatus !== 'Cotizacion' ? 0 : 1;
      },
    },
    salesordertype: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignVIA', value);
      },
      get() {
        return this.type;
      },
    },
    percent: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignFlete', value);
      },
      get() {
        return this.flete;
      },
    },
    ivachec: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignIVACHE', value);
      },
      get() {
        return this.ivachek;
      },
    },
    lista: {
      set(value) {
        this.$store.dispatch('SalesorderFrom/update', value);
      },
      get() {
        return this.vv;
      },
    },
    idPedido: {
      set(value) {
        this.$store.dispatch('salesorder/toAssigntoAssignId', value);
      },
      get() {
        return this.salesorderid;
      },
    },
    cal: {
      get() {
        const freight = this.cart.reduce((a, e) => a + (e.weight * e.quantity), 0);
        console.log(freight);
        return freight.toFixed(2);
      },
    },
    sub: {
      get() {
        const subtotal = this.cart.reduce((a, e) => a + (e.listprice * e.quantity), 0);
        return subtotal.toFixed(2);
      },
    },
    profile() {
      return Profile.get();
    },
    hearders() {
      return this.isAdd || this.isUpdate === true ? this.headers : this.headersShow;
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAFE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapMutations('salesorder', ['SET_CART']),
    ...mapMutations('salesorder', ['SET_FORM']),
    ...mapActions('salesorder', ['add']),
    async addItem() {
      this.dialog = true;
    },
    removeItem(item) {
      const shoppingCart = this.cart;
      shoppingCart.splice(shoppingCart.indexOf(item), 1);
      console.log(shoppingCart);
      this.$store.commit('salesorder/SET_CART', shoppingCart);
      this.calculate();
    },
    selectItem(item) {
      this.dialog = false;
      const exists = this.exists(item);
      switch (exists) {
        case -1:
          this.cart.push(
            {
              productcode: item.productcode,
              productname: item.productname,
              quantity: 1,
              center: item.center,
              listprice: item.listprice,
              weight: item.weight,
              total: this.subtotal,
              lista_precio: item.lista_precio,
              imagenurl: item.imagenurl,
              almacen: item.almacen,
              tipo: item.tipo,
              // coin: this.porcheck === false ? 'USD' : 'VES', // colcar la lista nueva
            },
          );
          this.calculate();
          console.log(this.cart);
          break;
        default:
          this.$store.commit('message/MESSAGE', 'El producto ya se encuentra en el carrito');
          this.$store.commit('message/TYPE', 'info');
          this.$store.dispatch('message/run');
      }
    },
    exists(item) {
      return this.cart.findIndex(
        (e) => e.productcode === item.productcode && e.center === item.center,
      );
    },
    async buscarlista() {
      this.request.check_porcentaje = 0;
      this.request.VP = this.salesordertype;
      this.request.company_id = this.profile.organization.id;
      this.request.user_id = this.profile.user.id;
      this.request.method = 'pricebookuser';
      this.pricebookuser = (await salesorderForm(this.request)).data;// Condicion de venta
      this.itemsDialog = (await salesorderItemCar(this.request)).data;
    },
    calculate() {
      const subtotal = this.cart.reduce((a, e) => a + (e.listprice * e.quantity), 0);
      this.$store.commit('salesorder/SET_SUBTOTAL', subtotal.toFixed(2));
      const freight = this.cart.reduce((a, e) => a + (e.weight * e.quantity), 0);
      this.$store.commit('salesorder/SET_FREIGHT', freight.toFixed(2));
      const iva = subtotal * 0.16;
      this.$store.commit('salesorder/SET_IVA', iva.toFixed(2));
      const total = subtotal;
      this.$store.commit('salesorder/SET_TOTAL', total.toFixed(2));
    },
    subTotal() {
      const subtotal = this.cart.reduce((a, e) => a + (e.listprice * e.quantity), 0);
      this.$store.commit('salesorder/SET_SUBTOTAL', subtotal.toFixed(2));
    },
    async update(item) {
      const exists = this.exists(item);

      this.request.id = this.idPedido;
      this.request.product = this.cart[exists].productcode;
      this.request.lista = this.cart[exists].lista_precio;

      this.listaItems = (await updateListaPrecio(this.request)).data;
      const precio = this.listaItems;

      if (exists >= 0 && precio.length > 0) {
        this.cart.splice(exists, 1, {
          productcode: item.productcode,
          productname: item.productname,
          quantity: item.quantity,
          center: item.center,
          listprice: precio[0].listprice,
          weight: item.weight,
          lista_precio: item.lista_precio.text.slice(0, -3),
          imagenurl: item.imagenurl,
          almacen: item.almacen,
          tipo: item.tipo,
          // coin: this.porcheck === false ? 'USD' : 'VES',// colcar la lista nueva
        });
      } else {
        this.$store.commit('message/MESSAGE', 'El producto no esta previsto para la lista');
        this.$store.commit('message/TYPE', 'info');
        this.$store.dispatch('message/run');
        this.cart.splice(exists, 1, {
          productcode: item.productcode,
          productname: item.productname,
          quantity: item.quantity,
          center: item.center,
          listprice: 0,
          weight: item.weight,
          lista_precio: item.lista_precio.text.slice(0, -3),
          imagenurl: item.imagenurl,
          almacen: item.almacen,
          tipo: item.tipo,
          // coin: this.porcheck === false ? 'USD' : 'VES',// colcar la lista nueva
        });
      }

      this.$store.commit('salesorder/SET_CART', this.cart);
      this.calculate();
    },
    habilitar() {
      return this.salesordertype === 'PBL67' ? 1 : 0;
    },
  },
  async created() {
    // this.calculate();
    this.request.method = 'pricebookuser';
    this.pricebookuser = (await salesorderForm(this.request)).data; // Condicion de venta
    this.itemsDialog = (await salesorderItemCar(this.request)).data;
  },
};
</script>
