<template>
  <v-container fluid>
    <v-row class="mt-2 pt-2">
      <v-col cols="12" sm="4" md="2" class="mt-0 pt-0">
        <v-text-field
          class="mt-0 pt-0"
          label="Cliente"
          required
          v-model="form.customerno"
          v-if="isAdd || isUpdate"
          append-icon="mdi-account"
          readonly
          @click="dialog = !dialog"
          @click:append="dialog = !dialog"
          counter="10"
          color="grey darken-4"
        >
        </v-text-field>
        <v-text-field
          class="mt-0 pt-0"
          label="Cliente"
          required
          v-model="form.customerno"
          v-else-if="isShow"
          append-icon="mdi-account"
          readonly
          counter="10"
          color="grey darken-4"
        >
        </v-text-field>
        <v-text-field
          class="mt-0 pt-0"
          label="Cedula/Rif"
          required
          v-model="form.esporadico"
          v-show="esporadicoF"
          counter="10"
          color="grey darken-4"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="8" md="6" class="mt-0 pt-0">
        <v-text-field
          class="mt-0 pt-0"
          label="Nombre"
          v-show="esporadicoT"
          v-model="form.accountname"
          readonly
          counter="50"
          color="grey darken-4"
        >
        </v-text-field>
        <v-text-field
          class="mt-0 pt-0"
          label="Nombre 2"
          v-show="esporadicoF"
          v-model="form.accountname"
          counter="50"
          color="grey darken-4"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="2" class="mt-0 pt-0">
        <v-text-field
          class="mt-0 pt-0"
          label="Zona"
          readonly
          v-model="form.zone"
          counter="10"
          color="grey darken-4"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="2" class="mt-0 pt-0">
        <v-text-field
          class="mt-0 pt-0"
          label="Límite de Crédito"
          readonly
          v-model="form.limite_credito"
          counter="20"
          color="grey darken-4"
        >
        </v-text-field>
      </v-col>
      <v-col v-show="this.espo == 0 & esporadicoF == false" cols="12" sm="4" md="2" class="mt-0 pt-0">
       <v-text-field
          class="mt-0 pt-0"
          label="Teléfono"
          v-model="form.phone"
          v-if="isShow"
          readonly
          counter="12"
          color="grey darken-4"
        >
       </v-text-field>
       <v-text-field
          class="mt-0 pt-0"
          label="Teléfono"
          v-model="form.phone"
          v-if="isUpdate || isAdd"
          readonly
          counter="12"
          color="grey darken-4"
        >
       </v-text-field>
      </v-col>
      <v-col v-show="this.espo == 1 || (esporadicoF == true & this.espo == '')" cols="12" sm="4" md="2" class="mt-0 pt-0">
       <v-text-field
          class="mt-0 pt-0"
          label="Teléfono"
          v-model="form.telefono"
          v-if="isShow"
          readonly
          counter="12"
          color="grey darken-4"
        >
        </v-text-field>
        <v-text-field
          class="mt-0 pt-0"
          label="Teléfono"
          v-model="form.telefono"
          v-if="isUpdate || isAdd"
          counter="12"
          color="grey darken-4"
        >
        </v-text-field>
      </v-col>
      <v-col  v-show="this.espo == 0 & esporadicoF == false" cols="12" sm="8" class="mt-0 pt-0">
        <v-text-field
          class="mt-0 pt-0"
          label="Correo"
          v-model="form.email1"
          readonly
          v-if="isShow"
          counter="50"
          color="grey darken-4"
        >
        </v-text-field>
        <v-text-field
          class="mt-0 pt-0"
          label="Correo"
          v-model="form.email1"
          readonly
          v-else-if="isUpdate || isAdd"
          counter="50"
          color="grey darken-4"
        >
        </v-text-field>
      </v-col>
      <v-col v-show="this.espo == 1 || (esporadicoF == true & this.espo == '')" cols="12" sm="8" class="mt-0 pt-0">
        <v-text-field
          class="mt-0 pt-0"
          label="Correo"
          v-model="form.correo"
          v-if="isShow"
          readonly
          counter="50"
          color="grey darken-4"
        >
        </v-text-field>
        <v-text-field
          class="mt-0 pt-0"
          label="Correo"
          v-model="form.correo"
          v-else-if="isUpdate || isAdd"
          counter="50"
          color="grey darken-4"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="2" class="mt-0 pt-0">
        <v-text-field
          class="mt-0 pt-0"
          label="Nro. Pedido SAP"
          v-model="NrSap"
          v-if="isAdd"
          counter="21"
          color="grey darken-4"
          readonly
        >
        </v-text-field>
        <v-text-field
          class="mt-0 pt-0"
          label="Nro. Pedido SAP"
          v-model="NrSap"
          v-else-if="isUpdate || isShow"
          readonly
          counter="21"
          color="grey darken-4"
        >
        </v-text-field>
      </v-col>
      <v-col  v-show="this.espo == 0 & esporadicoF == false" cols="12" sm="4" class="mt-0 pt-0">
        <v-text-field
          class="mt-0 pt-0"
          label="Población"
          v-model="form.poblac"
          v-if="isAdd || isUpdate"
          counter="21"
          color="grey darken-4"
          solo
          outlined
        >
        </v-text-field>
        <v-text-field
          class="mt-0 pt-0"
          label="Poblacion"
          v-model="form.poblac"
          v-else-if="isShow"
          readonly
          counter="21"
          color="grey darken-4"
          solo
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col v-show="this.espo == 1 || (esporadicoF == true & this.espo == '')" cols="12" sm="4" class="mt-0 pt-0">
        <v-text-field
          class="mt-0 pt-0"
          label="Población"
          v-model="form.poblacion"
          v-if="isAdd || isUpdate"
          counter="21"
          color="grey darken-4"
          solo
          outlined
        >
        </v-text-field>
        <v-text-field
          class="mt-0 pt-0"
          label="Poblacion"
          v-model="form.poblacion"
          v-else-if="isShow"
          readonly
          counter="21"
          color="grey darken-4"
          solo
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col  v-show="this.espo == 0 & esporadicoF == false" cols="12" sm="4" class="mt-0 pt-0">
        <v-text-field
          class="mt-0 pt-0"
          label="Código Postal"
          v-model="Codigo"
          v-if="isAdd || isUpdate"
          counter="21"
          color="grey darken-4"
          solo
          outlined
        >
        </v-text-field>
        <v-text-field
          class="mt-0 pt-0"
          label="Código Postal"
          v-model="Codigo"
          v-else-if="isShow"
          readonly
          counter="21"
          color="grey darken-4"
          solo
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col v-show="this.espo == 1 || (esporadicoF == true & this.espo == '')" cols="12" sm="4" class="mt-0 pt-0">
        <v-text-field
          class="mt-0 pt-0"
          label="Código Postal"
          v-model="Codigo"
          v-if="isAdd || isUpdate"
          counter="21"
          color="grey darken-4"
          solo
          outlined
        >
        </v-text-field>
        <v-text-field
          class="mt-0 pt-0"
          label="Código Postal"
          v-model="Codigo"
          v-else-if="isShow"
          readonly
          counter="21"
          color="grey darken-4"
          solo
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col  v-show="this.espo == 0 & esporadicoF == false" cols="12" sm="4" class="mt-0 pt-0">
        <v-autocomplete
          class="mt-0 pt-0"
          v-model="form.regi"
          v-if="isShow"
          :items="Region"
          label="Región"
          color="grey darken-4"
          readonly
          solo
          outlined
         >
        </v-autocomplete>
        <v-autocomplete
          class="mt-0 pt-0"
          v-model="form.regi"
          v-else-if="isUpdate || isAdd"
          :rules="Rules"
          :items="Region"
          label="Región"
          color="grey darken-4"
          solo
          outlined
         >
        </v-autocomplete>
      </v-col>
      <v-col v-show="this.espo == 1 || (esporadicoF == true & this.espo == '')" cols="12" sm="4" class="mt-0 pt-0">
        <v-autocomplete
          class="mt-0 pt-0"
          v-model="form.region"
          v-if="isShow"
          :items="Region"
          label="Región"
          color="grey darken-4"
          readonly
          solo
          outlined
         >
        </v-autocomplete>
        <v-autocomplete
          class="mt-0 pt-0"
          v-model="form.region"
          v-else-if="isUpdate || isAdd"
          :rules="Rules"
          :items="Region"
          label="Región"
          color="grey darken-4"
          solo
          outlined
         >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" class="mt-0 pt-0">
        <v-autocomplete
          class="mt-0 pt-0"
          v-model="form.sostatus"
          v-if="isAdd"
          :items="Estatu"
          label="Estatus"
          color="grey darken-4"
          solo
          outlined
        ></v-autocomplete>
         <v-autocomplete
           class="mt-0 pt-0"
          v-model="form.sostatus"
          v-else-if="isShow"
          :items="estatus"
          label="Estatus"
          solo
          readonly
          color="grey darken-4"
          outlined
        ></v-autocomplete>
        <v-autocomplete
          class="mt-0 pt-0"
          v-model="form.sostatus"
          v-else-if="isUpdate"
          :items="estatus"
          label="Estatus"
          color="grey darken-4"
          solo
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" class="mt-0 pt-0">
        <v-autocomplete
          class="mt-0 pt-0"
          v-model="paymen"
          v-if="isShow"
          :items="payment"
          label="Condición de Cobro"
          color="grey darken-4"
          readonly
          solo
          outlined
        ></v-autocomplete>
         <v-autocomplete
          class="mt-0 pt-0"
          v-model="paymen"
          v-else-if="isUpdate || isAdd"
          :items="payment"
          :rules="Rules"
          label="Condición de Cobro"
          color="grey darken-4"
          solo
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" class="mt-0 pt-0">
        <v-autocomplete
          class="mt-0 pt-0"
          v-model='viapayment'
          v-if="isShow"
          :items="viaspago"
          label="Vía de pago"
          color="grey darken-4"
          readonly
          solo
          outlined
        ></v-autocomplete>
        <v-autocomplete
          class="mt-0 pt-0"
          v-model='viapayment'
          v-else-if="isUpdate || isAdd"
          :items="viaspago"
          :rules="Rules"
          label="Vía de pago"
          color="grey darken-4"
          solo
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" class="mt-0 pt-0">
        <v-autocomplete
          class="mt-0 pt-0"
          v-model="formAccount"
          v-if="isShow"
          :items="account"
          label="Tipo de Cliente"
          color="grey darken-4"
          readonly
          solo
          outlined
         >
        </v-autocomplete>
        <v-autocomplete
          class="mt-0 pt-0"
          v-model="formAccount"
          v-else-if="isUpdate || isAdd"
          :rules="Rules"
          :items="account"
          label="Tipo de Cliente"
          color="grey darken-4"
          solo
          outlined
         >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" class="mt-0 pt-0">
        <v-autocomplete
          class="mt-0 pt-0"
          v-model="transpor"
          v-if="isShow"
          :items="transport"
          label="Transporte"
          color="grey darken-4"
          readonly
          solo
          outlined
        ></v-autocomplete>
        <v-autocomplete
          class="mt-0 pt-0"
          v-model="transpor"
          v-else-if="isUpdate || isAdd"
          :items="transport"
          :rules="Rules"
          label="Transporte"
          color="grey darken-4"
          solo
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3" class="mt-0 pt-0">
        <v-textarea
          v-model="form.bill_street"
          label="Dirección de facturación"
          rows="2"
          color="grey darken-4"
          readonly
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="3" class="mt-0 pt-0">
        <v-textarea
          v-if="isAdd || isUpdate"
          v-model="form.ship_street"
          label="Dirección de Envío"
          :rules="Rules"
          rows="2"
          color="grey darken-4"
        ></v-textarea>
        <v-textarea
          v-if="isShow"
          v-model="form.ship_street"
          label="Dirección de Envío"
          rows="2"
          color="grey darken-4"
          required
          readonly
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="3" class="mt-0 pt-0">
        <v-textarea
          v-if="isAdd || isUpdate"
          v-model="obeservation"
          label="Observacion"
          title="Campo uso Interno"
          :rules="Rules"
          rows="2"
          color="grey darken-4"
        ></v-textarea>
        <v-textarea
          v-if="isShow"
          v-model="obeservation"
          label="Observacion"
          title="Campo uso Interno"
          rows="2"
          color="grey darken-4"
          readonly
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="3" class="mt-0 pt-0">
        <v-textarea
          v-if="isAdd || isUpdate"
          v-model="nota"
          label="Nota"
          title="Campo para el ejecutivo"
          :rules="RulesNota"
          rows="2"
          color="grey darken-4"
        ></v-textarea>
        <v-textarea
          v-if="isShow"
          v-model="nota"
          label="Nota"
          title="Campo para el ejecutivo"
          rows="2"
          color="grey darken-4"
          readonly
        ></v-textarea>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="75%" persistent>
      <v-card>
      <v-toolbar :color="profile.organization.color">
        <h1 class="title mt-2 white--text">
          Clientes
        </h1>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = !dialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
           <!--<v-btn @click="dialog = !dialog">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>-->
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="mt-5 pt-5"
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            color="grey darken-4"
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="header"
            :items="items"
            :items-per-page="5"
            class="elevation-12 mt-0 pt-0"
            :search="search">
            <template v-slot:[`item.select`]="{ item }">
              <v-icon
                title="Seleccionar"
                @click="setAccount(item)"
                x-large>
                mdi-check-circle-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapMutations, mapState } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import { customers } from '../../config/api/customers';
// eslint-disable-next-line import/extensions
import { salesorderForm } from '../../config/api/salesorder';

export default {
  name: 'salesorder-form',
  data: () => ({
    esporadicoT: true,
    esporadicoF: false,
    espordico: '',
    Estatu: [
      { value: 'Creado', text: 'Creado' },
      { value: 'Cotizacion', text: 'Cotizacion' },
    ],
    Rules: [
      (v) => !!v || 'Este campo no puede estar vacío',
    ],
    RulesNota: [
      (v) => !!v || 'Campo para el ejecutivo',
    ],
    header: [
      { text: 'Código', value: 'account_no', align: 'center' },
      { text: 'Nombre', value: 'accountname', align: 'center' },
      {
        text: 'Seleccionar', align: 'center', sortable: false, value: 'select',
      },
    ],
    items: [],
    transport: [],
    pricebookuser: [],
    viaspago: [
      { value: 'R', text: 'Retención' },
      { value: 'C', text: 'Cheque' },
      { value: 'D', text: 'Depósito' },
      { value: 'E', text: 'Efectivo' },
      { value: 'P', text: 'Punto de Vta. Virtual' },
      { value: 'T', text: 'Transferencia' },
    ],
    percentaje: [
      { value: '0.00', text: '0%' },
      { value: '0.02', text: '2%' },
      { value: '0.03', text: '3%' },
      { value: '0.04', text: '4%' },
      { value: '0.05', text: '5%' },
      { value: '0.06', text: '6%' },
      { value: '0.07', text: '7%' },
      { value: '0.08', text: '8%' },
    ],
    Sucursal: [
      { value: '4102', text: 'CNR BQT' },
      { value: '4202', text: 'CNR CSS' },
    ],
    Region: [
      { value: 'AMA', text: 'Amazonas' },
      { value: 'ANZ', text: 'Anzoategui' },
      { value: 'APU', text: 'Apure' },
      { value: 'ARA', text: 'Aragua' },
      { value: 'BAR', text: 'Barinas' },
      { value: 'BOL', text: 'Bolívar' },
      { value: 'CAR', text: 'Carabobo' },
      { value: 'COJ', text: 'Cojedes' },
      { value: 'DA', text: 'Delta Amacuro' },
      { value: 'DF', text: 'Distrito Capital' },
      { value: 'FAL', text: 'Falcon' },
      { value: 'GUA', text: 'Guarico' },
      { value: 'LAR', text: 'Lara' },
      { value: 'MER', text: 'Merida' },
      { value: 'MIR', text: 'Miranda' },
      { value: 'MON', text: 'Monagas' },
      { value: 'NE', text: 'Nueva Esparta' },
      { value: 'POR', text: 'Portuguesa' },
      { value: 'SUC', text: 'Sucre' },
      { value: 'TAC', text: 'Tachira' },
      { value: 'TRU', text: 'Trujillo' },
      { value: 'VAR', text: 'Vargas' },
      { value: 'YAR', text: 'Yaracuy' },
      { value: 'ZUL', text: 'Zulia' },
    ],
    dialog: false,
    search: '',
    listaItems: [],
    poblacionn: '',
    request: {
      user_id: null,
      company_id: null,
      roles_id: null,
    },
  }),
  computed: {
    ...mapState('userprofile', ['profile']),
    ...mapState('salesorder', ['title', 'isShow', 'isAdd', 'show', 'estatus', 'typeOrder', 'type', 'pago', 'isUpdate', 'form', 'cart']),
    ...mapState('salesorder', ['salesorderid', 'cliente_esporadico', 'region', 'sucursal', 'nrosap', 'poblacion', 'codigo_postal', 'notas', 'description', 'ejecutivo', 'vp', 'accountType', 'cond_compra', 'transporte', 'account', 'ejecutivos', 'salesorder', 'payment', 'flete', 'listprice', 'Via_pago', 'type']),
    salesordertype: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignVIA', value);
      },
      get() {
        return this.type;
      },
    },
    transpor: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignTransporte', value);
      },
      get() {
        return this.transporte;
      },
    },
    paymen: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignCond_Compra', value);
      },
      get() {
        return this.cond_compra;
      },
    },
    regiones: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignRegion', value);
      },
      get() {
        return this.region;
      },
    },
    formAccount: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignACCOUNT', value);
      },
      get() {
        return this.accountType;
      },
    },
    viapayment: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignVP', value);
      },
      get() {
        return this.vp;
      },
    },
    formEjecutivo: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignEJECUTIVO', value);
      },
      get() {
        return this.ejecutivo;
      },
    },
    obeservation: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignNotas', value);
      },
      get() {
        return this.notas;
      },
    },
    nota: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignDescription', value);
      },
      get() {
        return this.description;
      },
    },
    Poblacion: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignPoblacion', value);
      },
      get() {
        return this.poblacion;
      },
    },
    Codigo: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignCodigo', value);
      },
      get() {
        return this.codigo_postal;
      },
    },
    NrSap: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignNoSap', value);
      },
      get() {
        return this.nrosap;
      },
    },
    percent: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignFlete', value);
      },
      get() {
        return this.flete;
      },
    },
    condicion: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignCondicion', value);
      },
      get() {
        return this.listprice;
      },
    },
    sucursales: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignSUCURSAL', value);
      },
      get() {
        return this.sucursal;
      },
    },
    espo: {
      set(value) {
        this.$store.dispatch('salesorder/toAssigntoAssignCLIENTE_ESPORADICO', value);
      },
      get() {
        console.log(this.cliente_esporadico);
        return this.cliente_esporadico;
      },
    },
    idPedido: {
      set(value) {
        this.$store.dispatch('salesorder/toAssigntoAssignId', value);
      },
      get() {
        return this.salesorderid;
      },
    },
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapMutations('salesorder', ['SET_CART']),
    ...mapMutations('salesorder', ['SET_FREIGHT', 'SET_SUBTOTAL', 'SET_TOTAL', 'SET_DATA_CLIENTE_ESPORADICO']),
    setAccount(item) {
      console.log(item.zona);
      this.form.customerno = item.account_no;
      this.form.accountname = item.accountname;
      this.form.zone = item.zona;
      this.form.limite_credito = item.limite_credito;
      this.form.phone = item.phone;
      this.form.telefono = item.telefono;
      this.form.bill_street = item.direction;
      this.form.ship_street = item.direction2;
      this.form.esporadico = item.esporadico;
      this.form.region = item.region;
      this.form.poblacion = item.poblacion;
      this.form.poblac = item.poblac;
      this.form.regi = item.regi;
      this.dialog = false;
      console.log('espo->'); console.log(this.espo); console.log(item.esporadico); console.log('jj');
      if (item.esporadico === '1') {
        this.esporadicoT = false;
        this.esporadicoF = true;
        this.espordico = null;
        console.log(this.espordico);
        console.log(this.esporadicoF); console.log('-espoF cambio a true');
        console.log(this.esporadicoT); console.log('-espoT cambio a false');
        // this.form.email1 = item.correo;
        console.log('XD');
      } else {
        if (this.profile.organization.id === 2) {
          this.form.zone = 'Z4005'; // se esta seteando por motivo a que hay cliente que estan para los dos sociedades y en este caso toma la zona de maferca
        }
        this.esporadicoT = true;
        this.esporadicoF = false;
        this.espordico = 0;
        this.form.email1 = item.correo;
        console.log(this.espordico);
        console.log(this.form.email1);
        console.log(this.esporadicoF); console.log('-comunF cambio a false');
        console.log(this.esporadicoT); console.log('-comunT cambio a true');
      }
    },
    calculate() {
      console.log('paso');
      const subtotal = this.cart.reduce((a, e) => a + (e.listprice * e.quantity), 0);
      console.log(subtotal);
      this.$store.commit('salesorder/SET_SUBTOTAL', subtotal.toFixed(2));
      const freight = (this.percent * subtotal);
      this.$store.commit('salesorder/SET_FREIGHT', freight.toFixed(2));
      const iva = subtotal * 0.16;
      this.$store.commit('salesorder/SET_IVA', iva.toFixed(2));
      const total = freight + subtotal;
      this.$store.commit('salesorder/SET_TOTAL', total.toFixed(2));
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.$store.dispatch('salesorder/clean');
        this.$store.commit('message/MESSAGE', api.data.message);
        this.$store.commit('message/TYPE', 'success');
        this.$store.dispatch('message/run');
        this.$store.dispatch('salesorder/data', this.profile);
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error);
      }
      this.$store.commit('message/LOADING', false);
    },
    validate() {
      if (this.form.customerno === '') {
        this.alert('El RIF o CI esta vacio');
        return 0;
      }
      this.data();
      return 1;
    },
    alert(message) {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', 'info');
      this.$store.dispatch('message/run');
    },
  },
  async mounted() {
    try {
      if (this.espo === '1') {
        this.esporadicoF = true;
        this.esporadicoT = false;
        console.log(this.esporadicoF);
      } else {
        this.esporadicoF = false;
        console.log(this.esporadicoF);
      }
      this.request.company_id = this.profile.organization.id;
      this.request.user_id = this.profile.user.id;
      this.request.roles_id = this.profile.roluser.roles_id;
      this.$store.dispatch('salesorder/initializingForm', this.request);
      this.items = (await customers(this.request)).data;
      this.request.method = 'pricebookuser';
      this.pricebookuser = (await salesorderForm(this.request)).data;// Condicion de venta
      this.request.method = 'transport';
      this.transport = (await salesorderForm(this.request)).data;// trasporte
      /* this.request.method = 'region';
      this.region = (await salesorderForm(this.request)).data;
      this.request.method = 'sostatus';
      this.sostatus = (await salesorderForm(this.request)).data;// estatus */
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>
