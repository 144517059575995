<template>
  <v-app>
    <Toolbar/>
    <v-main>
    <v-container fluid class="mt-5 pt-5">
      <v-card>
        <v-card-title>
          <v-icon class='mr-2'> mdi-application </v-icon>
          {{profile.organization.name}} - Pedidos Comunes
          <v-spacer></v-spacer>
          <v-text-field
            v-model='search'
            append-icon='mdi-magnify'
            label='Buscar'
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-btn
          title='Agregar'
          v-show="validar(profile.permissions,'1002')"
          class='mx-4 mb-4'
          tile
          color='grey lighten-2'
          @click='add()'>
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
        <v-card class='mx-auto' width='98%'>
          <v-data-table
            :headers='header'
            :items='items'
            :search='search'
            no-data-text='no hay datos'
            :loading='loading'
            loading-text='cargando'
            sort-by='user_name'
            class='elevation-24 mytable'
            :header-props="{
              sortByText: 'Ordenar',
              class: 'title',
            }"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-text': 'Pedidos por Página',
            }"
          >
            <template v-slot:[`item.acciones`]='{ item }'>
              <v-btn icon>
                <v-icon
                  class='px-auto'
                  v-if="validar(profile.permissions, '1001')"
                  title='Visualizar'
                  @click='show(item,item.salesorder_no)'
                  color='#0091EA'>
                  mdi-eye
                </v-icon>
              </v-btn>
               <v-btn icon v-show="(item.sostatus === 'Aprobado' || item.sostatus === 'Por Contabilizar SM') & validar(profile.permissions, '1012')">
                <v-icon class='px-auto' title='Anular' @click='anular(item)' color='orange'>
                  mdi-delete-variant
                </v-icon>
              </v-btn>
              <v-btn icon v-show="(item.sostatus === 'Creado' || item.sostatus === 'Por Aprobar' || item.sostatus === 'Bloqueado' || item.sostatus === 'Cotizacion' || item.sostatus === 'Promocion' || item.sostatus === 'Aprobado') & item.nrosap == null & validar(profile.permissions, '1004')">
                <v-icon class='px-auto'  title='Editar' color='grey'
                 @click='update(item) /*tratar(item)*/'>
                  mdi-circle-edit-outline
                </v-icon>
              </v-btn>
              <v-btn icon v-show="(item.sostatus === 'Creado' || item.sostatus === 'Por Aprobar' || item.sostatus === 'Bloqueado' || item.sostatus === 'Cotizacion' || item.sostatus === 'Promocion' || item.sostatus === 'Aprobado') & item.nrosap == null & validar(profile.permissions, '1003')">
                <v-icon class='px-auto' title='Eliminar' @click='remove(item)' color='red'>
                  mdi-delete-circle
                </v-icon>
              </v-btn>
              <v-btn icon v-show="(item.sostatus === 'FacturadoNE' || item.sostatus === 'Por Contabilizar SM') & item.pedidoFinanciero == null">
                <v-icon
                  class='px-auto'
                  title='Pedido Financiero'
                  @click='showTasa(item)'
                  color='success'
                >
                mdi-arrow-down-bold-circle-outline
                </v-icon>
              </v-btn>
              <v-btn :disabled="habilitar()"
                v-show="validar(profile.permissions,'1008')"
                class="px-3 ml-1" v-if="(item.sostatus === 'Aprobado' & item.nrosap == null)"
                icon title='Crear Pedido SAP' @click='sap(item,1)'
               >
              <v-img src='system/sap.png' height='auto' width='30'></v-img>
              </v-btn>
              <v-btn
                v-show="validar(profile.permissions,'1022') & profile.organization.id == 1"
                class="px-3 ml-1" v-if="(item.sostatus === 'Creado' & item.nrosap == null)"
                icon  @click='liberarPedido(item)'
               >
              <v-icon class='px-auto' title='Liberar Pedido' color='green'>
              mdi mdi-lock-open
              </v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon
                  class='px-auto'
                  title='Descargar'
                  @click='download(item)'
                  color='blue'
                >
                mdi-file-alert-outline
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.sostatus`]='{ item }'>
              <v-chip :color='getColor(item.sostatus)' small text-color='white'>
                {{ item.sostatus }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
        <salesorderView></salesorderView>
        <SalesorderTasa/>
      </v-card>
    </v-container>
    </v-main>
    <Historicobcv/>
    <SetLogout/>

    <v-dialog v-model="modalDelete" open-delay="" max-width="290" transition="dialog-top-transition">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark height="50px">
          <v-icon class="mr-2">
            mdi-application
          </v-icon>
            <h1 class="title mt-2 white--text">
              Borrar de pedido
            </h1>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="modalDelete = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4 title">
          ¿Está seguro que desea borrar el pedido seleccionado?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="modalDelete = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" outlined @click="removeValidate(item)">
            Sí, borrar!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalAnular" open-delay="" max-width="290" transition="dialog-top-transition">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark height="50px">
          <v-icon class="mr-2">
            mdi-application
          </v-icon>
            <h1 class="title mt-2 white--text">
              Anular de pedido
            </h1>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="modalAnular = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4 title">
          ¿Está seguro que desea anular el pedido seleccionado?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="modalAnular = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" outlined @click="anularValidate()">
            Sí, anular!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import 'jspdf-autotable';
// eslint-disable-next-line import/extensions
import {
  salesorder, bsap, remover, anular, tasaBcv, getliberarPedido,
} from '../../config/api/salesorder';
// eslint-disable-next-line import/extensions
import SalesorderView from './SalesorderView';
// eslint-disable-next-line import/extensions
import SalesorderTasa from './SalesorderTasa';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
// import { salesorderItemCar } from '../../config/api/products';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';

export default {
  name: 'salesorder',
  components: {
    SalesorderView, Toolbar, SalesorderTasa, SetLogout, Historicobcv,
  },
  data: () => ({
    search: '',
    itemSelected: null,
    modalAnular: false,
    modalDelete: false,
    heading: 'Pedido',
    headersMaferca: [
      // { text: 'id', value: 'salesorderid' },
      { text: 'Pedido', value: 'salesorder_no' },
      { text: 'Rif', value: 'customerno' },
      { text: 'Cliente', value: 'accountname' },
      { text: 'Creado', value: 'createdtime' },
      { text: 'Modificado', value: 'modifiedtime' },
      { text: 'Zona', value: 'zone' },
      { text: 'Monto', value: 'total' },
      { text: 'Transporte', value: 'transporte' },
      { text: 'Estatus', value: 'sostatus' },
      { text: 'Acciones', value: 'acciones' },
      { text: 'Pedido SAP', value: 'nrosap' },
      { text: 'Factura SAP', value: 'factsap' },
      { text: 'Pedido Financiero', value: 'pedidoFinanciero' },
      { text: 'Tipo', value: 'type' },
    ],
    headersDefault: [
      // { text: 'id', value: 'salesorderid' },
      { text: 'Pedido', value: 'salesorder_no' },
      { text: 'Rif', value: 'customerno' },
      { text: 'Cliente', value: 'accountname' },
      { text: 'Creado', value: 'createdtime' },
      { text: 'Modificado', value: 'modifiedtime' },
      { text: 'Zona', value: 'zone' },
      { text: 'Monto', value: 'total' },
      { text: 'Transporte', value: 'transporte' },
      { text: 'Estatus', value: 'sostatus' },
      { text: 'Acciones', value: 'acciones' },
      { text: 'Pedido SAP', value: 'nrosap' },
      { text: 'Factura SAP', value: 'factsap' },
      { text: 'Pedido Financiero', value: 'pedidoFinanciero' },
      { text: 'Tipo', value: 'type' },
    ],
    items: [],
    dataPedido: [],
    loading: true,
    bootsap: true,
    mat: [],
    tasa: null,
  }),
  computed: {
    header() {
      return this.profile.organization.name === 'Maferca' ? this.headersMaferca : this.headersDefault;
    },
    ...mapState('salesorder', ['item']),
    profile() {
      return Profile.get();
    },
    salesordertype: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignVIA', value);
      },
      get() {
        return this.type;
      },
    },
  },
  methods: {
    ...mapActions('salesorder', ['data', 'add', 'update', 'show', 'showTasa']),
    getColor(sostatus) {
      switch (sostatus) {
        case 'Cotizacion':
          return 'blue';
        case 'Creado':
          return 'orange';
        case 'Por Aprobar':
          return 'purple';
        case 'Aprobado':
          return 'green';
        case 'Por Contabilizar SM':
          return 'brown';
        default:
          return 'red';
      }
    },
    validar(permiso, accion) {
      let valor = false;
      permiso.forEach((permisos) => {
        if (permisos.accion === accion) {
          valor = true;
        }
      });
      return valor;
    },
    data(item, liberar) {
      this.bootsap = false;
      const requests = {
        Crmid: item.salesorderid,
        Pedido: item.salesorder_no,
        user_id: this.profile.user.id,
        company_id: this.profile.organization.id,
        permisos: this.profile.permissions,
        tipo: 'PE',
        validar: liberar,
      };
      return requests;
    },
    remove(item) {
      this.itemSelected = item;
      this.modalDelete = !this.modalDelete;
    },
    anular(item) {
      this.itemSelected = item;
      this.modalAnular = !this.modalAnular;
    },
    async removeValidate() {
      if (this.itemSelected !== null) {
        this.response1(await remover(this.data(this.itemSelected, 0)));
      }
    },
    async anularValidate() {
      if (this.itemSelected !== null) {
        this.response1(await anular(this.data(this.itemSelected, 0)));
      }
    },
    download(item) {
      const form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('target', '_blank');
      form.setAttribute('action', `//www.sistemacrm.com.ve/api/v2/getpdf?order=${item.salesorder_no}&session={%22appname%22:%22webcrm%22,%22user_id%22:%22${this.profile.user.id} %22,%22session_key%22:%22${this.profile.sesionkey}%22}`);

      document.body.appendChild(form);
      form.submit();
    },
    async sap(item, validar) {
      item.items.forEach((element) => {
        this.mat.push(
          element.tipo,
        );
      });
      const respuesta = this.mat.includes('GENE');

      if (respuesta === true) {
        try {
          this.$store.commit('message/MESSAGE', 'el pedido posee un Material Generico');
          this.$store.commit('message/TYPE', 'info');
          this.$store.dispatch('message/run');
        } catch (error) {
          // eslint-disable-next-line no-alert
          alert(error);
        }
      } else {
        this.response(await bsap(this.data(item, validar)));
      }
    },
    async liberarPedido(item) {
      const requests = {
        Crmid: item.salesorderid,
      };
      this.response1(await getliberarPedido(requests));
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.$store.commit('message/URL', api.data.url);
        this.alert(api.data.message, 'success');

        if (api.data.url.trim() !== '') {
          this.bootsap = false;
        }
      } catch (error) {
        // eslint-disable-next-line no-alert
        this.$store.commit('message/URL', '');
        this.alert(error, 'error');
      }
      this.$store.commit('message/LOADING', false);
    },
    response1(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.alert(api.data.message, 'success');
      } catch (error) {
        // eslint-disable-next-line no-alert
        this.$store.commit('message/URL', '');
        this.alert(error, 'error');
      }
      this.$store.commit('message/LOADING', false);
    },
    alert(message, type = 'info') {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', type);
      this.$store.commit('message/RELOAD', true);
      this.$store.dispatch('message/run');
    },
    habilitar() {
      return this.bootsap === false;
    },
    response2(api) {
      if (api.status === 202) {
        // this.$store.commit('message/LOADING', true);
        try {
          this.$store.commit('message/MESSAGE', api.data.message);
          this.$store.commit('message/TYPE', 'warning');
          this.$store.dispatch('message/run');
        } catch (error) {
          // eslint-disable-next-line no-alert
          alert(error);
        }
        // this.$store.commit('message/LOADING', false);
      }
    },
  },
  async mounted() {
    if (Profile.check()) {
      const permissions = this.profile.permissions.find((e) => e.accion === '1001');
      if (!permissions) {
        this.$router.push('notAuthorized');
      }
      const requests = {
        user_id: this.profile.user.id,
        company_id: this.profile.organization.id,
        rol: this.profile.roluser.roles_id,
        VP: this.salesordertype,
        method: 'comunes',
      };
      const item = await salesorder(requests);
      this.items = item.data;
      this.loading = false;
      this.tasa = (await tasaBcv()).data;
      console.log(this.tasa);
    } else {
      this.$router.push('login');
    }
  },
};
</script>
